const initState = {
  authError: null,
  auth: {
    logged_in: localStorage.getItem('logged_in') ? localStorage.getItem('logged_in') : false,
    user: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null,
  }
}

const authReducer = (state = initState, action) => {
  switch (action.type) {
    case 'SETTINGS_COMPLETED':
      return {
        ...state,
        auth: {
          ...state.auth,
          user: {
            ...state.auth.user,
            settingsCompleted: action.payload.status
          }
        }
      }

    case 'LOGIN_ERROR':
      return {
        ...state,
        authError: action.err
      }

    case 'LOGIN_SUCCESS':
      localStorage.setItem('logged_in', true);
      localStorage.setItem('user', JSON.stringify(action.payload.data.user));
      return {
        ...state,
        auth: {
          logged_in: true,
          user: action.payload.data.user,
        },
        authError: null
      }

    case 'SIGNOUT_SUCCESS':
      localStorage.clear();
      return {
        ...state,
        auth: {
          logged_in: false,
          domid: '',
        },
        authError: null
      }

    default:
      return state
  }
};

export default authReducer;