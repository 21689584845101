import React from 'react';
import ImportAccount from './ImportAccount';
import AddAccount from './AddAccount';
import ManageAccounts from './ManageAccounts';
import { Row, Tabs, Card } from 'antd';
const { TabPane } = Tabs;

class LTDSettings extends React.Component {

  state = {
    activeTab: "1"
  }

  componentDidUpdate(prevProps) {
    if (this.props.match.params.tab !== prevProps.match.params.tab) {
      this.setState({
        activeTab: `${this.props.match.params.tab}`
      });
    }
  }

  componentDidMount() {
    if (this.props.match.params.tab && ["1", "2", "3"].indexOf(this.props.match.params.tab) !== -1) {
      this.setState({
        activeTab: `${this.props.match.params.tab}`
      });
    }
  }

  render() {
    const props = this.props;
    const PageWrapper = (children) => (
      <div className="store-users">
        <h3>LTD Settings</h3>
        <Row style={{ marginBottom: "20px" }}>
          {children}
        </Row>
      </div>
    );
    return PageWrapper(
      <Card>
        <Tabs
          tabPosition="left"
          activeKey={this.state.activeTab}
          onChange={(key) => props.history.push(`/ltd/${key}`)}>
          <TabPane tab="Import Account" key="1">{this.state.activeTab === "1" && <ImportAccount {...props} />}</TabPane>
          <TabPane tab="Add Account" key="2">{this.state.activeTab === "2" && <AddAccount {...props} />}</TabPane>
          <TabPane tab="Manage Accounts" key="3">{this.state.activeTab === "3" && <ManageAccounts {...props} />}</TabPane>
        </Tabs>
      </Card>
    )
  }
}

export default LTDSettings;