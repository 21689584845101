import React, { Component } from 'react';
import { Form, Input, Button, Tooltip, Icon } from 'antd';

class SMTPSettings extends Component {

  state = {
    saving: this.props.saving
  }

  componentDidUpdate() {
    if (this.state.saving !== this.props.saving) {
      this.setState({ saving: this.props.saving });
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.updateSettings(values);
      }
    });
  }

  componentDidMount() {
    this.props.form.setFieldsValue(this.props.settings)
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form onSubmit={this.handleSubmit} className="smtp-form">
        <Form.Item label="From Name">
          {getFieldDecorator('from_name', {
            rules: [{ required: true, message: 'This is a required field' }],
          })(
            <Input />
          )}
        </Form.Item>
        <Form.Item label="From Email">
          {getFieldDecorator('from_email', {
            rules: [{ required: true, message: 'This is a required field' }, { type: "email", message: 'Please enter a valid email' }],
          })(
            <Input />
          )}
        </Form.Item>
        <Form.Item label="Reply-To Email">
          {getFieldDecorator('replyto_email', {
            rules: [{ required: true, message: 'This is a required field' }, { type: "email", message: 'Please enter a valid email' }],
          })(
            <Input />
          )}
        </Form.Item>
        <Form.Item label={<span>Franchise Address <Tooltip title="This will be mentioned in the footer for the emails that will be sent to your users"><Icon type="info-circle" theme="filled" style={{ cursor: "pointer" }} /></Tooltip></span>}>
          {getFieldDecorator('franchise_address', {
            rules: [{ required: true, message: 'This is a required field' }],
          })(
            <Input />
          )}
        </Form.Item>
        <Form.Item label={<span>Support URL <Tooltip title="This will be mentioned in the footer for the emails sent to your users to provide a way for them to contact you"><Icon type="info-circle" theme="filled" style={{ cursor: "pointer" }} /></Tooltip></span>}>
          {getFieldDecorator('franchise_supporturl', {
            rules: [{ required: true, message: 'This is a required field' }],
          })(
            <Input />
          )}
        </Form.Item>
        <Form.Item label="SMTP Host">
          {getFieldDecorator('smtp_host', {
            rules: [{ required: true, message: 'This is a required field' }],
          })(
            <Input />
          )}
        </Form.Item>
        <Form.Item label="SMTP Port">
          {getFieldDecorator('smtp_port', {
            rules: [{ required: true, message: 'This is a required field' }],
          })(
            <Input />
          )}
        </Form.Item>
        <Form.Item label="SMTP Username">
          {getFieldDecorator('smtp_username', {
            rules: [{ required: true, message: 'This is a required field' }],
          })(
            <Input />
          )}
        </Form.Item>
        <Form.Item label="SMTP Password">
          {getFieldDecorator('smtp_password', {
            rules: [{ required: true, message: 'Please input your Password!' }],
          })(
            <Input.Password visibilityToggle={true} />
          )}
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={this.state.saving} className="login-form-button">Save</Button>
        </Form.Item>
      </Form >
    );
  }
}

export default Form.create()(SMTPSettings);