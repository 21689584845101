import axios from 'axios';

export const signIn = (credentials) => {
  const { email, password } = credentials;
  return (dispatch, getState) => {
    axios({
      url: '/login/email',
      method: 'post',
      data: {
        email,
        password
      }
    }).then((res) => {
      if (res.data.success === 1) {
        dispatch({ type: 'LOGIN_SUCCESS', payload: { data: res.data.data } });
      } else {
        dispatch({ type: 'LOGIN_ERROR', err: res.data.msg });
      }
    }).catch((err) => {
      dispatch({ type: 'LOGIN_ERROR', err });
    });
  }
}

export const checkLogin = () => {
  return (dispatch) => {
    axios({
      url: '/login/check_login',
      method: 'post',
    }).then((res) => {
      if (res.data.success === 1) {
        dispatch({ type: 'LOGIN_SUCCESS', payload: { data: res.data.data } });
      } else {
        dispatch({ type: 'SIGNOUT_SUCCESS' });
      }
    }).catch((err) => {
      dispatch({ type: 'SIGNOUT_SUCCESS' });
    });
  }
}


export const signOut = () => {
  return (dispatch) => {
    axios({
      url: '/login/logout',
      method: 'post',
    }).then((res) => {
      if (res.data.success === 1) {
        dispatch({ type: 'SIGNOUT_SUCCESS' });
      }
    })
  }
}

export const markSettingsComplete = (status) => {
  return (dispatch) => {
    dispatch({ type: 'SETTINGS_COMPLETED', payload: { status } });
  }
}