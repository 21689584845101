import React, { Component } from 'react';
import { Input, Table, Alert, Skeleton, Row, Col, Card, Tooltip, Modal, message } from 'antd';
import axios from 'axios';
import moment from 'moment';
const Search = Input.Search;
const { confirm } = Modal;

class ManageAccounts extends Component {
  state = {
    accounts: [],
    loading: true,
    error: false,
    errorMsg: '',
    total_views: 0,
    total_views_alloted: 0,
    filters: {
      search: '',
    },
    editViewsModal: {
      visible: false,
      id: undefined
    },
    paging: {
      page: 1,
      per_page_count: 10
    }
  }

  fetchAccounts() {
    axios({
      url: '/user/get_accounts',
      method: 'post',
      data: {
        page: this.state.paging.page,
        per_page_count: this.state.paging.per_page_count,
        filters: this.state.filters
      }
    }).then((res) => {
      if (res.data.success === 1) {
        this.setState({
          accounts: res.data.accounts,
          paging: { ...this.state.paging, total: res.data.total },
          total_views_alloted: res.data.total_views_alloted,
          total_views: res.data.total_views,
          loading: false,
        })
      } else {
        this.setState({
          error: true,
          errorMsg: res.data.msg,
          loading: false
        })
      }
    }).catch((e) => {
      this.setState({
        error: true,
        errorMsg: e.message,
        loading: false
      })
    })
  }

  componentDidMount() {
    this.fetchAccounts();
  }

  onFilterChange(filters) {
    this.setState({
      filters: { ...this.state.filters, ...filters },
      loading: true,
      paging: {
        page: 1,
        per_page_count: 10,
      }
    }, () => this.fetchAccounts())
  }

  handlePageChange = (pagination) => {
    this.setState({
      paging: { ...this.state.paging, page: pagination.current },
    }, () => this.fetchAccounts());
  }

  editViews = (id) => {
    confirm({
      title: 'Enter the number of views you want to allot to this account',
      content: <Input type="number" placeholder="Enter Number of Views to allot" min={1} ref={viewsInput => this.viewsInput = viewsInput} />,
      onOk: () => {
        const views = this.viewsInput.state.value;
        if (views && views.length && parseInt(views) > 0) {
          axios({
            url: '/user/set_account_views',
            method: 'post',
            data: {
              domid: id,
              views
            }
          }).then((res) => {
            if (res.data.success === 1) {
              this.fetchAccounts();
              message.success("Views updated successfully");
            } else {
              message.error(res.data.msg);
            }
          }).catch((e) => {
            message.error(e.message);
          })
        } else {
          message.error("Please enter a valid number greater than 0");
        }
      }
    });
  }

  render() {
    const PageWrapper = (children) => (
      <div className="store-users">
        <Row style={{ marginBottom: "20px" }}>
          <Col md={{ span: 12, offset: 12 }} lg={{ span: 8, offset: 16 }}>
            <Search
              placeholder="search accounts"
              enterButton="Search"
              size="large"
              onSearch={value => this.onFilterChange({ search: value })}
            />
          </Col>
        </Row>
        <Row gutter={30} style={{ marginBottom: "20px" }}>
          <Col xs={8}>
            <Card loading={this.state.loading} style={{ background: "#fafafa" }}><h2>{this.state.total_views}</h2><h4>Total Views</h4></Card>
          </Col>
          <Col xs={8}>
            <Card loading={this.state.loading} style={{ background: "#fafafa" }}><h2>{this.state.total_views_alloted}</h2><h4>Total Views Alloted</h4></Card>
          </Col>
          <Col xs={8}>
            <Card loading={this.state.loading} style={{ background: "#fafafa" }}><h2>{this.state.total_views - this.state.total_views_alloted}</h2><h4>Unallocated Views</h4></Card>
          </Col>
        </Row>
        {children}
      </div>
    );

    if (this.state.loading) {
      return PageWrapper(
        <div style={{ marginBottom: "30px" }}>
          <Skeleton active />
        </div>
      )
    }

    if (!this.state.loading && this.state.error) {
      return PageWrapper(
        <div>
          <Row>
            <Alert
              message="There was an error"
              description={this.state.errorMsg}
              type="error"
            />
          </Row>
        </div>
      )
    }

    const columns = [
      {
        title: 'User',
        dataIndex: 'dom_user',
        render: (_, record) => <Tooltip title={record.dom_user.email}>{record.dom_user.name}</Tooltip>
      },
      {
        title: 'Account Name',
        dataIndex: 'dom_name',
      },
      {
        title: 'Created On',
        dataIndex: 'dom_created',
        render: text => moment(text).format('D MMM, YYYY')
      },
      {
        title: 'Websites Added',
        dataIndex: 'dom_websites',
        render: (websites) => websites.map((w, i) => <span key={w}>{w} {i < (websites.length - 1) && <span>, </span>}</span>)
      },
      {
        title: 'Views Alloted',
        dataIndex: 'dom_user.views_alloted',
      },
      {
        title: 'Views Exhausted',
        dataIndex: 'dom_user.views_used',
      },
      {
        title: 'Actions',
        dataIndex: 'id',
        render: id => <span><a href="#/" onClick={() => this.editViews(id)}>Set Views</a></span>
      }
    ];

    const pagination = {
      current: this.state.paging.page,
      pageSize: this.state.paging.per_page_count,
      total: this.state.paging.total
    };

    return PageWrapper(
      <Card>
        <Table
          columns={columns}
          rowKey={record => record.key}
          dataSource={this.state.accounts}
          pagination={pagination}
          loading={this.state.loading}
          onChange={(pagination) => this.handlePageChange(pagination)}
        />
      </Card>
    )
  }
}

export default ManageAccounts;