import React, { Component } from 'react';
import { Form, Input, Button, message, Table, Row, Alert, Icon } from 'antd';
import axios from 'axios';
import moment from 'moment';

class ImportAccount extends Component {

  state = {
    saving: false,
    migrations: [],
    loading: true,
    error: false,
    errorMsg: ''
  }

  handleSubmit(e) {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.setState({ saving: true }, () => {
          axios({
            url: '/user/import_account',
            method: 'post',
            data: {
              id: values.domid
            }
          }).then((res) => {
            if (res.data.success === 1) {
              message.success('An email with a confirmation link has been sent to the account owner.');
              this.props.form.setFieldsValue({ domid: '' });
              this.setState({ saving: false });
              this.fetchMigrations();
            } else {
              message.error(res.data.msg);
              this.setState({ saving: false });
            }
          }).catch((e) => {
            message.error(e.message);
            this.setState({ saving: false });
          })
        })
      }
    });
  }

  fetchMigrations = () => {
    this.setState({
      loading: true,
      migrations: [],
      error: false,
      errorMsg: '',
    }, () => {
      axios({
        url: '/user/get_migrations',
        method: 'post'
      }).then((res) => {
        if (res.data.success === 1) {
          this.setState({
            loading: false,
            migrations: res.data.migrations,
            error: false,
            errorMsg: '',
          })
        } else {
          this.setState({
            loading: false,
            error: true,
            errorMsg: res.data.msg,
          })
        }
      }).catch((e) => {
        this.setState({
          loading: false,
          error: true,
          errorMsg: e.message
        })
      })
    })
  }

  resendEmail(record) {
    this.setState({
      migrations: this.state.migrations.map((m) => {
        if (m._id === record._id) {
          m.in_progress = true;
          return m;
        } else {
          return m;
        }
      })
    }, () => {
      axios({
        url: '/user/resend_migration_email',
        method: 'post',
        data: {
          id: record._id
        }
      }).then(() => {
        this.setState({
          migrations: this.state.migrations.map((m) => {
            if (m._id === record._id) {
              m.in_progress = false;
              return m;
            } else {
              return m;
            }
          })
        })
        message.success("Email sent successfully!");
      }).catch((e) => {
        message.error(e.message);
      })
    })
  }

  componentDidMount() {
    this.fetchMigrations();
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const columns = [
      {
        title: 'Account Id',
        dataIndex: 'id',
      },
      {
        title: 'Request Date',
        dataIndex: 'date',
        render: (date) => moment(date).format("DD MMM, YYYY")
      },
      {
        title: 'Status',
        dataIndex: 'status',
        render: (text, record) => {
          const statuses = {
            'FAILED': "There was an error migrating this account!",
            'EMAIL_SENT': 'Confirmation Email Sent',
            'MIGRATION_IN_PROCESS': 'Migration in process',
            'COMPLETE': "Migration Complete"
          };
          if (text === "EMAIL_SENT") {
            return <span>{statuses[text]} <a href="#/" style={{ pointerEvents: record.in_progress ? 'none' : 'auto' }} onClick={() => this.resendEmail(record)}>(Resend {record.in_progress && <Icon type="loading" />})</a></span>
          } else {
            return statuses[text];
          }
        }
      },
      {
        title: 'Completion Date',
        dataIndex: 'completion_date',
        render: (date) => date ? moment(date).format("DD MMM, YYYY") : ''
      },
    ];
    return (
      <React.Fragment>
        <Form onSubmit={(e) => this.handleSubmit(e)}>
          <Form.Item
            label="Import Account"
          >
            {getFieldDecorator('domid', {
              rules: [{
                required: true, message: 'Please enter your Account ID',
              }],
            })(
              <Input placeholder="Enter Account Id" />
            )}
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" loading={this.state.saving}>Import Account</Button>
          </Form.Item>
        </Form>

        {!this.state.loading && this.state.error &&
          <Row style={{ marginBottom: 30 }}>
            <Alert
              message="There was an error"
              description={this.state.errorMsg}
              type="error"
            />
          </Row>
        }

        <Table
          columns={columns}
          rowKey={record => record.id}
          dataSource={this.state.migrations}
          pagination={false}
          loading={this.state.loading}
        />
      </React.Fragment>
    )
  }
}

export default Form.create()(ImportAccount);