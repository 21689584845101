import React, { Component } from 'react';
import { Input, Button, Row, Col, Alert, Skeleton, message, Card } from 'antd';
import axios from 'axios';

import './index.scss';

class ProfileForm extends Component {
  state = {
    profile: {
      user_fname: '',
      user_email: '',
      password: '',
      confirm_password: ''
    },
    errors: {
      user_fname: '',
      confirm_password: '',
    },
    formDirty: false,
    hasError: false,
    loading: true,
  };

  componentDidMount() {
    axios({
      url: '/user/get_profile',
      method: 'post',
    }).then((res) => {
      if (res.data.success === 1) {
        this.setState({
          profile: { ...res.data.profile, password: '', confirm_password: '' },
          loading: false,
        })
      } else {
        this.setState({
          error: true,
          errorMsg: res.data.msg,
          loading: false
        })
      }
    }).catch((e) => {
      this.setState({
        error: true,
        errorMsg: e.message,
        loading: false
      })
    })
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.validateProfile(() => {
      if (!this.state.formDirty) {
        axios({
          url: '/user/update_profile',
          method: 'post',
          data: { ...this.state.profile, user_email: undefined }
        }).then((res) => {
          if (res.data.success === 1) {
            message.success("Updated Successfully");
            this.setState({
              profile: { ...this.state.profile, password: '', confirm_password: '' }
            })
          } else {
            message.error(res.data.msg);
          }
        }).catch((e) => {
          message.error(e.message);
        })
      }
    })
  }

  updateField(field, value) {
    this.setState({
      profile: { ...this.state.profile, [field]: value }
    }, () => this.validateProfile())
  }

  validateProfile(callback) {
    const profile = this.state.profile;
    let errors = {};
    if (profile.user_fname === "") {
      errors.user_fname = "Please enter your full name";
    }
    if (profile.password !== '' || profile.confirm_password !== '') {
      if (profile.password !== profile.confirm_password) {
        errors.confirm_password = "Passwords don't match";
      }
    }
    let formDirty = false;
    if (Object.keys(errors).length) {
      formDirty = true;
    }
    this.setState({
      errors,
      formDirty
    }, () => { callback && callback() })
  }

  render() {

    if (this.state.loading) {
      return (
        <Card><Skeleton active /></Card>
      )
    }

    if (!this.state.loading && this.state.error) {
      return (
        <Row>
          <Alert
            message="There was an error"
            description={this.state.errorMsg}
            type="error"
          />
        </Row>
      )
    }
    return (
      <React.Fragment>
        <h3>Profile Settings</h3>
        <Card className="profile-settings">
          <form layout="horizontal" onSubmit={(e) => this.handleSubmit(e)} className="profile-form" noValidate>
            <Row style={{ marginBottom: "1.5em" }}>
              <Col xs={24} md={12} className="column">
                <div className="ant-form-item-control">
                  <p className="form-labels" style={{ margin: 0 }}>Email</p>
                  <Input type="email" value={this.state.profile.user_email} disabled={true} style={{ width: "100%", maxWidth: "400px" }} />
                </div>
              </Col>
              <Col xs={24} md={12} className="column">
                <div className={`ant-form-item-control ${this.state.errors.user_fname ? 'has-error' : ''}`}>
                  <p className="form-labels" style={{ margin: 0 }}>Name</p>
                  <Input style={{ width: "100%", maxWidth: "400px" }} defaultValue={this.state.profile.user_fname} onChange={(e) => this.updateField('user_fname', e.target.value)} />
                  {this.state.errors.user_fname && <div className="ant-form-explain">{this.state.errors.user_fname}</div>}
                </div>
              </Col>

            </Row>

            <Row style={{ marginBottom: "1.5em" }}>
              <Col xs={24} md={12} className="column">
                <div className={`ant-form-item-control ${this.state.errors.confirm_password ? 'has-error' : ''}`}>
                  <p className="form-labels" style={{ margin: 0 }}>New Password</p>
                  <Input type="password" value={this.state.profile.password} style={{ width: "100%", maxWidth: "400px" }} onChange={(e) => this.updateField('password', e.target.value)} />
                  {this.state.errors.confirm_password && <div className="ant-form-explain">{this.state.errors.confirm_password}</div>}
                </div>
              </Col>


              <Col xs={24} md={12} className="column">
                <div className={`ant-form-item-control ${this.state.errors.confirm_password ? 'has-error' : ''}`}>
                  <p className="form-labels" style={{ margin: 0 }}>Confirm Password</p>
                  <Input type="password" value={this.state.profile.confirm_password} onBlur={this.handleConfirmBlur} style={{ width: "100%", maxWidth: "400px" }} onChange={(e) => this.updateField('confirm_password', e.target.value)} />
                  {this.state.errors.confirm_password && <div className="ant-form-explain">{this.state.errors.confirm_password}</div>}
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={24} className="column">
                <Button
                  style={{ background: "#36C626", borderColor: "#36C626", color: "white" }}
                  htmlType="submit"
                  disabled={this.state.formDirty}
                >Save Profile</Button>
              </Col>
            </Row>
          </form >
        </Card >
      </React.Fragment>
    );
  }
}

export default ProfileForm;