import React, { Component } from 'react';
import { Route, BrowserRouter, Switch } from 'react-router-dom'
import LoginForm from './Components/Login/LoginForm/';
import Dashboard from './Components/Dashboard/';
import Users from './Components/Dashboard/Users';
import Accounts from './Components/Dashboard/Accounts';
import AddUser from './Components/Dashboard/Users/AddUser';
import AddAccount from './Components/Dashboard/Accounts/AddAccount';
import Settings from './Components/Dashboard/Settings';
import LTDSettings from './Components/Dashboard/LTDSettings';
import Migration from './Components/Login/Migration';
import ProfileSettings from './Components/Dashboard/ProfileSettings';

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <div className="content-body">
          <Switch>
            <Route exact path='/' render={(props) => <Dashboard {...{ ...props, title: 'LetMailbox' }} />} />
            <Route path='/login' render={(props) => <LoginForm {...{ ...props, title: 'LetMailbox | Login' }} />} />
            <Route exact path='/migration/:hash' render={(props) => <Migration {...{ ...props, title: 'LetMailbox | Account Migration' }} />} />

            <Route exact path='/accounts' render={(props) => <Dashboard {...{ ...props, title: 'LetMailbox | Accounts' }} > <Accounts {...props} /></Dashboard>} />
            <Route exact path='/add_account' render={(props) => <Dashboard {...{ ...props, title: 'LetMailbox | Add Account' }} > <AddAccount {...props} /></Dashboard>} />
            <Route exact path='/edit_account/:user_id' render={(props) => <Dashboard {...{ ...props, title: 'LetMailbox | Edit Account' }} > <AddAccount {...props} /></Dashboard>} />

            <Route exact path='/users' render={(props) => <Dashboard {...{ ...props, title: 'LetMailbox | Users' }} > <Users {...props} /></Dashboard>} />
            <Route exact path='/add_user' render={(props) => <Dashboard {...{ ...props, title: 'LetMailbox | Add User' }} > <AddUser {...props} /></Dashboard>} />
            <Route exact path='/edit_user/:user_id' render={(props) => <Dashboard {...{ ...props, title: 'LetMailbox | Edit User' }} > <AddUser {...props} /></Dashboard>} />

            <Route exact path='/ltd/:tab?' render={(props) => <Dashboard {...{ ...props, title: 'LetMailbox | LTD Settings' }} > <LTDSettings {...props} /></Dashboard>} />

            <Route exact path='/settings' render={(props) => <Dashboard {...{ ...props, title: 'LetMailbox | Settings' }} > <Settings {...props} /></Dashboard>} />

            <Route exact path='/profile' render={(props) => <Dashboard {...{ ...props, title: 'LetMailbox | Profile' }} > <ProfileSettings {...props} /></Dashboard>} />
          </Switch>
        </div>
      </BrowserRouter >
    );
  }
}

export default App;
