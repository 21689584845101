import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { Row, Card } from "antd";
import logo from "../../assets/images/logos/logo_dark.svg";
import { connect } from "react-redux";
import { checkLogin } from "../../store/actions/authActions";

import "./LoginPageContainer.scss";

class LoginPageContainer extends Component {
  state = {
    loading: true
  };
  componentDidMount() {
    this.props.checkLoggedIn();
  }
  componentDidUpdate() {
    if (!this.props.auth.logged_in && this.state.loading) {
      this.setState({
        loading: false
      });
    }
  }
  render() {
    const { auth } = this.props;
    if (auth.logged_in) return <Redirect to={`/`} />;
    return (
      <div className="login-page-container">
        <div className="login-container">
          <Row type="flex" justify="center">
            <img className="logo" src={logo} alt="Logo" />
          </Row>
          <Row type="flex" justify="center">
            {/* <p className="tagline">Dropshipping made easy.</p> */}
          </Row>
          {this.state.loading && <Card loading={this.state.loading} />}
          {!this.state.loading && this.props.children}
          <div className="login-page-footer">
            <Row type="flex" justify="center">
              <p>copyright &copy; {new Date().getFullYear()} LetMailbox</p>
            </Row>
            <Row type="flex" justify="center">
              <ul className="footer-links">
                <li>
                  <a
                    href="https://letx.co/terms"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    Terms
                  </a>
                </li>
                <li>
                  <a
                    href="https://letx.co/privacy-policy"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    Privacy
                  </a>
                </li>
                <li>
                  <a
                    href="https://help.letmailbox.com"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    Help
                  </a>
                </li>
              </ul>
            </Row>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth.auth
  };
};

const mapDispatchToProps = dispatch => {
  return {
    checkLoggedIn: () => dispatch(checkLogin())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginPageContainer);
