import React, { Component } from 'react';
import { Input, Form, Button, Select, message, Skeleton, Alert, Row } from 'antd';
import axios from 'axios';

const { Option } = Select;

class AddAccount extends Component {
  state = {
    loading: true,
    saving: false,
    error: false,
    errorMsg: '',
    id: this.props.match.params.user_id ? this.props.match.params.user_id : undefined,
    editingAccount: undefined,
    users: [],
  }

  componentWillMount() {
    axios({
      url: '/user/get_account_details',
      method: 'post',
      data: {
        id: this.state.id,
      }
    }).then((res) => {
      if (res.data.success === 1) {
        let state = {
          users: res.data.users,
          editingAccount: res.data.account ? res.data.account : undefined,
          loading: false,
        }
        this.setState(state, () => {
          if (this.state.editingAccount) {
            this.props.form.setFieldsValue({
              name: this.state.editingAccount.name,
              user: this.state.editingAccount.user,
              websites: this.state.editingAccount.websites,
            });
          }
        })
      } else {
        this.setState({
          error: true,
          errorMsg: res.data.msg,
          loading: false
        })
      }
    }).catch((e) => {
      this.setState({
        error: true,
        errorMsg: e.message,
        loading: false
      })
    })
  }

  handleSubmit(e) {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const websites = values.websites;
        if (websites.filter((w) => !w.match(/^(https?:\/\/)?([a-zA-Z0-9]+\.)?[a-zA-Z0-9][a-zA-Z0-9-]+\.[a-zA-Z]{2,6}?$/)).length === 0) {
          this.setState({
            saving: true,
          }, () => {
            axios({
              url: '/user/save_account',
              method: 'post',
              data: {
                id: this.state.id ? this.state.id : undefined,
                ...values,
                params: this.state.params,
              }
            }).then((res) => {
              if (res.data.success === 1) {
                message.success('Account saved successfully!');
                this.props.history.push('/accounts');
              } else {
                message.error(res.data.msg);
                this.setState({ saving: false });
              }
            }).catch((e) => {
              message.error(e.message);
              this.setState({ saving: false });
            })
          })
        }
      }
    });
  }

  render() {
    const { getFieldDecorator, getFieldValue } = this.props.form;
    if (this.state.loading) {
      return (
        <div style={{ marginBottom: "30px" }}>
          <Skeleton active />
        </div>
      )
    }

    if (!this.state.loading && this.state.error) {
      return (
        <div>
          <Row>
            <Alert
              message="There was an error"
              description={this.state.errorMsg}
              type="error"
            />
          </Row>
        </div>
      )
    }

    return (
      <Form onSubmit={(e) => this.handleSubmit(e)}>
        <h3>{this.state.id ? 'Edit Account' : 'Add Account'}</h3>
        <Form.Item
          label="Name"
        >
          {getFieldDecorator('name', {
            rules: [{
              required: true, message: 'Please enter an account name',
            }],
          })(
            <Input />
          )}
        </Form.Item>
        <Form.Item
          label="User"
        >
          {getFieldDecorator('user', {
            rules: [{
              required: true, message: 'Please select a user',
            }],
          })(
            <Select
              style={{ width: "100%" }}
              filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
              {this.state.users.map(u => <Option key={u.id} value={u.id}>{u.name} ({u.email})</Option>)}
            </Select>
          )}
        </Form.Item>
        <Form.Item
          label="Websites"
          validateStatus={
            getFieldValue('websites') && getFieldValue('websites').filter((w) => !w.match(/^(https?:\/\/)?([a-zA-Z0-9]+\.)?[a-zA-Z0-9][a-zA-Z0-9-]+\.[a-zA-Z]{2,6}?$/)).length ? 'error' : 'success'
          }
          help={getFieldValue('websites') && getFieldValue('websites').filter((w) => !w.match(/^(https?:\/\/)?([a-zA-Z0-9]+\.)?[a-zA-Z0-9][a-zA-Z0-9-]+\.[a-zA-Z]{2,6}?$/)).length ? 'Please enter valid domains only' : ''}
        >
          {getFieldDecorator('websites', {
            rules: [{
              required: true,
            }],
          })(
            <Select
              dropdownClassName='websites-dropdown'
              mode="tags"
              style={{ width: "100%" }}
              filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
            </Select>
          )}
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" loading={this.state.saving}>{this.state.id ? 'Update Account' : 'Add Account'}</Button>
        </Form.Item>
      </Form>
    )
  }
}

export default Form.create()(AddAccount);