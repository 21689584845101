import React, { Component } from 'react';
import GeneralSettings from './GeneralSettings';
import DomainSettings from './DomainSettings';
import { Row, Tabs, Card, Alert, message } from 'antd';
import SMTPSettings from './SMTPSettings';
import axios from 'axios';
import { connect } from 'react-redux';
import { markSettingsComplete } from '../../../store/actions/authActions';
const { TabPane } = Tabs;

class Settings extends Component {
  state = {
    loading: true,
    saving: false,
    error: false,
    errorMsg: '',
    setting: {
      general: {},
      domain: {},
      smtp: {
        from_name: '',
        from_email: '',
        replyto_email: '',
        franchise_address: '',
        franchise_supporturl: '',
        smtp_username: '',
        smtp_password: '',
        smtp_host: ''
      }
    }
  }

  updateSettings(type, settings) {
    this.setState({ saving: true }, () => {
      axios({
        url: '/user/update_user_settings',
        method: 'post',
        data: {
          type,
          settings
        }
      }).then((res) => {
        if (res.data.success === 1) {
          this.setState({ saving: false });
          message.success('Settings updated successfully!');
          if (res.data.completed) {
            this.props.settingsCompleted(true);
          }
        } else {
          message.error(res.data.msg);
        }
      }).catch((e) => {
        message.error(e.message);
      })
    })
  }

  fetchSettings() {
    axios({
      url: '/user/get_user_settings',
      method: 'post',
    }).then((res) => {
      if (res.data.success === 1) {
        if (res.data.completed) {
          this.props.settingsCompleted(true);
        } else {
          this.props.settingsCompleted(false);
        }
        this.setState({
          loading: false,
          settings: {
            ...this.state.settings,
            ...res.data.settings
          }
        })
      } else {
        this.setState({
          error: true,
          errorMsg: res.data.msg ? res.data.msg : 'There was an error!',
          loading: false
        })
      }
    }).catch((e) => {
      this.setState({
        error: true,
        errorMsg: e.message,
        loading: false
      })
    })
  }

  componentDidMount() {
    this.fetchSettings()
  }

  render() {
    const PageWrapper = (children) => (
      <div className="store-users">
        <h3>Settings</h3>
        {!this.props.auth.user.settingsCompleted && <Alert
          message="Complete Account Setup"
          description="Please make sure that you have successfully saved General, Domain and SMTP settings in order to complete your account setup!"
          type="warning"
          showIcon
          style={{ margin: "10px 0" }}
        />}
        <Row style={{ marginBottom: "20px" }}>
          {children}
        </Row>
      </div>
    );

    if (this.state.loading) {
      return PageWrapper(<Card loading={true} />)
    }

    if (!this.state.loading && this.state.error) {
      return PageWrapper(
        <Alert
          message="Error"
          description={this.state.errorMsg}
          type="error"
          showIcon
        />
      )
    }

    return PageWrapper(
      <Card>
        <Tabs tabPosition="left">
          <TabPane tab="General Settings" key="1"><GeneralSettings saving={this.state.saving} settings={this.state.settings.general} logos={this.state.settings.logos ? this.state.settings.logos : {}} updateSettings={(settings) => this.updateSettings("general", settings)} /></TabPane>
          <TabPane tab="Domain Settings" key="2"><DomainSettings saving={this.state.saving} settings={this.state.settings.domain} updateSettings={(settings) => this.updateSettings("domain", settings)} /></TabPane>
          <TabPane tab="SMTP Settings" key="3"><SMTPSettings saving={this.state.saving} settings={this.state.settings.smtp} updateSettings={(settings) => this.updateSettings("smtp", settings)} /></TabPane>
        </Tabs>
      </Card>
    )
  }

}

const mapStateToProps = (state) => {
  return {
    auth: state.auth.auth
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    settingsCompleted: (status) => dispatch(markSettingsComplete(status))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Settings);