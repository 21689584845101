import React from "react";
import logo from "../../../assets/images/logos/logo_dark.svg";
import "./Footer.scss";

const Footer = props => {
  return (
    <footer>
      <div className="container">
        <div className="row">
          <div className="footer-content">
            <div className="footer-left">
              <span className="footer-logo">
                <img
                  src={logo}
                  alt="LetMailbox"
                  style={{ maxWidth: "200px" }}
                />
              </span>
              <span className="footer-links">
                <a
                  href="https://help.letmailbox.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Support
                </a>
                <a
                  href="http://letx.co/terms"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Terms of Service
                </a>
                <a
                  href="http://letx.co/privacy-policy"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Privacy Policy
                </a>
              </span>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
