import React, { Component } from 'react';
import { Upload, Icon } from 'antd';
import './index.scss';

class Uploader extends Component {
  state = {
    fileList: this.props.fileList ? this.props.fileList : []
  };

  handleChange = ({ fileList }) => this.setState({ fileList })

  render() {
    const { fileList } = this.state;
    const uploadButton = (
      <div>
        <Icon type="plus" />
        <div className="ant-upload-text">Upload</div>
      </div>
    );
    return (
      <div className="ant-form-item">
        <div className="ant-form-item-label"><label className="ant-form-item-required" title=""><span>{this.props.label}</span></label></div>
        <div className="uploader clearfix">
          <Upload
            name={this.props.name}
            action="/user/upload_logos"
            listType="picture-card"
            fileList={fileList}
            onChange={this.handleChange}
          >
            {fileList.length >= 1 ? null : uploadButton}
          </Upload>
        </div>
      </div>
    );
  }
}

export default Uploader;