import React, { Component } from 'react';
import { Input, Form, Button, message, Skeleton, Alert, Row } from 'antd';
import axios from 'axios';

class AddUser extends Component {
  state = {
    loading: true,
    saving: false,
    error: false,
    errorMsg: '',
    id: this.props.match.params.user_id ? this.props.match.params.user_id : undefined,
    editingUser: undefined,
    plans: [],
  }

  componentWillMount() {
    axios({
      url: '/user/get_user_details',
      method: 'post',
      data: {
        id: this.state.id,
      }
    }).then((res) => {
      if (res.data.success === 1) {
        let state = {
          plans: res.data.plans,
          editingUser: res.data.user ? res.data.user : undefined,
          loading: false,
        }
        this.setState(state, () => {
          if (this.state.editingUser) {
            this.props.form.setFieldsValue({
              name: this.state.editingUser.user_fname,
              email: this.state.editingUser.user_email,
              plans: this.state.editingUser.user_planid
            });
          }
        })
      } else {
        this.setState({
          error: true,
          errorMsg: res.data.msg,
          loading: false
        })
      }
    }).catch((e) => {
      this.setState({
        error: true,
        errorMsg: e.message,
        loading: false
      })
    })
  }

  handleSubmit(e) {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.setState({
          saving: true,
        }, () => {
          axios({
            url: '/user/save_user',
            method: 'post',
            data: {
              id: this.state.id ? this.state.id : undefined,
              ...values,
              params: this.state.params,
            }
          }).then((res) => {
            if (res.data.success === 1) {
              message.success('User saved successfully!');
              this.props.history.push('/users');
            } else {
              message.error(res.data.msg);
              this.setState({ saving: false });
            }
          }).catch((e) => {
            message.error(e.message);
            this.setState({ saving: false });
          })
        })
      }
    });
  }

  render() {
    const { getFieldDecorator } = this.props.form;

    if (this.state.loading) {
      return (
        <div style={{ marginBottom: "30px" }}>
          <Skeleton active />
        </div>
      )
    }

    if (!this.state.loading && this.state.error) {
      return (
        <div>
          <Row>
            <Alert
              message="There was an error"
              description={this.state.errorMsg}
              type="error"
            />
          </Row>
        </div>
      )
    }

    return (
      <Form onSubmit={(e) => this.handleSubmit(e)}>
        <h3>{this.state.id ? 'Edit User' : 'Add User'}</h3>
        <Form.Item
          label="Name"
        >
          {getFieldDecorator('name', {
            rules: [{
              required: true, message: 'Please enter your name',
            }],
          })(
            <Input />
          )}
        </Form.Item>
        <Form.Item
          label="E-mail"
        >
          {getFieldDecorator('email', {
            rules: [{
              type: 'email', message: 'Please enter a valid email address',
            }, {
              required: true, message: 'Please enter a valid email address',
            }],
          })(
            <Input disabled={this.state.editingUser ? true : false} />
          )}
        </Form.Item>
        <Form.Item
          label="Password"
        >
          {getFieldDecorator('password', {
            rules: [{
              required: this.state.editingUser ? false : true, message: 'Please enter a password',
            }],
          })(
            <Input type="password" />
          )}
        </Form.Item>
        {/* <Form.Item
          label="Plans"
        >
          {getFieldDecorator('plans', {
            rules: [{
              required: true, message: 'Please select a plan',
            }],
          })(
            <Select
              style={{ width: "100%" }}
              mode="multiple"
              filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
              {this.state.plans.map(p => <Option key={p.id} value={p.id}>{p.name}</Option>)}
            </Select>
          )}
        </Form.Item> */}

        <Form.Item>
          <Button type="primary" htmlType="submit" loading={this.state.saving}>{this.state.id ? 'Update User' : 'Add User'}</Button>
        </Form.Item>
      </Form>
    )
  }
}

export default Form.create()(AddUser);