import React from 'react';
import { Input, Form, Button, message } from 'antd';
import axios from 'axios';

class AddAccount extends React.Component {
  state = {
    loading: true,
    saving: false,
    error: false,
    errorMsg: '',
  }

  handleSubmit(e) {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.setState({
          saving: true,
        }, () => {
          axios({
            url: '/user/add_account_franchise',
            method: 'post',
            data: {
              id: this.state.id ? this.state.id : undefined,
              ...values,
              params: this.state.params,
            }
          }).then((res) => {
            if (res.data.success === 1) {
              message.success('Account Added successfully!');
              this.props.history.push('/ltd/3');
            } else {
              message.error(res.data.msg);
              this.setState({ saving: false });
            }
          }).catch((e) => {
            message.error(e.message);
            this.setState({ saving: false });
          })
        })
      }
    });
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form onSubmit={(e) => this.handleSubmit(e)}>
        <Form.Item
          label="Name"
        >
          {getFieldDecorator('name', {
            rules: [{
              required: true, message: 'Please enter your name',
            }],
          })(
            <Input />
          )}
        </Form.Item>
        <Form.Item
          label="E-mail"
        >
          {getFieldDecorator('email', {
            rules: [{
              type: 'email', message: 'Please enter a valid email address',
            }, {
              required: true, message: 'Please enter a valid email address',
            }],
          })(
            <Input disabled={this.state.editingUser ? true : false} />
          )}
        </Form.Item>
        <Form.Item
          label="Password"
        >
          {getFieldDecorator('password', {
            rules: [{
              required: this.state.editingUser ? false : true, message: 'Please enter a password',
            }],
          })(
            <Input type="password" />
          )}
        </Form.Item>
        <Form.Item
          label="List Name"
        >
          {getFieldDecorator('list_name', {
            rules: [{
              required: true, message: 'Please enter a list name',
            }],
          })(
            <Input />
          )}
        </Form.Item>


        <Form.Item>
          <Button type="primary" htmlType="submit" loading={this.state.saving}>Add Account</Button>
        </Form.Item>
      </Form>
    );
  }
}

export default Form.create()(AddAccount);